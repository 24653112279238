<template>
    <v-row justify="center" class="py-5" align-content="center" style="height: 100%;">
        <v-col cols="12" sm="6">
            <template v-if="!interactionId">
                <v-card>
                    <v-toolbar dense flat color="blue darken-2" dark>
                        <v-toolbar-title>Sign up</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <p>Creating a profile is completely free. What is your email address?</p>
                    </v-card-text>
                    <v-form @submit.prevent="signupWithUsername" onSubmit="return false;" @keyup.enter.native.prevent="signupWithUsername" class="mx-4 pb-6">
                        <v-text-field v-model="username" ref="usernameInput" dense solo color="blue darken-2" hint="This will be your username. You can change it later." placeholder="Email" :error-messages="inputError">
                            <template #prepend-inner>
                                <font-awesome-icon :icon="['fas', 'envelope']" fixed-width/> <!-- style="font-size: 20px;" -->
                            </template>
                        </v-text-field>
                        <RememberMeCheckbox v-model="isRememberMeChecked"/>
                        <v-row no-gutters justify="center">
                            <v-btn @click="signupWithUsername" elevation="4" color="blue darken-2 white--text" class="mt-4">Continue</v-btn>
                        </v-row>
                    </v-form>
                </v-card>
                <p class="mt-6 mb-12 grey--text text--darken-2 text-center">Already have a profile? <router-link :to="{ name: 'login' }">Sign in</router-link></p>
            </template>
            <v-alert type="error" v-if="serverError">
                An error occurred while processing your request. Please try again or contact customer support.
            </v-alert>
            <v-alert type="error" v-if="requestError">
                We could not send a verification email. Please try again. If the problem continues, try with a different email address or contact customer support.
            </v-alert>
            <v-alert type="error" v-if="forbiddenError">
                The link is expired or invalid. Check that the email you entered is correct and try again.
            </v-alert>
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
</style>

<script>
// import { toText } from '@libertyio/time-util-js';
import { mapState } from 'vuex';
import { isValidEmail } from '@/sdk/input';
import RememberMeCheckbox from '@/components/RememberMeCheckbox.vue';

export default {
    components: {
        RememberMeCheckbox,
    },
    data: () => ({
        interactionId: null, // null on when user first arrives, has value after email verification
        username: '',
        submitTimestamp: null,
        checkEmail: false,
        verificationExpires: null,
        serverError: false,
        serverErrorTimeout: null,
        requestError: false,
        requestErrorTimeout: null,
        inputError: null,
        inputErrorTimeout: null,
        forbiddenError: null,
        forbiddenErrorTimeout: null,
        isRememberMeChecked: false,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            focus: (state) => state.focus,
        }),
        // mainWebsiteURL() {
        //     return process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://brandprofile.org';
        // },
    },
    watch: {
        focus() {
            this.$nextTick(() => {
                setTimeout(() => { this.activate('usernameInput'); }, 1);
            });
        },
    },
    methods: {
        /*
        Resets the form by clearing all errors. Does NOT clear the interaction id or input.
        Call `switchAccount` to also clear the interaction id and input and really start over.
        */
        reset() {
            this.serverError = false;
            if (this.serverErrorTimeout) {
                clearTimeout(this.serverErrorTimeout);
                this.serverErrorTimeout = null;
            }
            this.requestError = false;
            if (this.requestErrorTimeout) {
                clearTimeout(this.requestErrorTimeout);
                this.requestErrorTimeout = null;
            }
            this.inputError = null;
            if (this.inputErrorTimeout) {
                clearTimeout(this.inputErrorTimeout);
                this.inputErrorTimeout = null;
            }
            this.forbiddenError = false;
            if (this.forbiddenErrorTimeout) {
                clearTimeout(this.forbiddenErrorTimeout);
                this.forbiddenErrorTimeout = null;
            }
            this.checkEmail = false;
            this.verificationExpires = null;
        },
        /**
         * Call this when the user enters an email address they want to use as their
         * primary email address and username
         */
        async signupWithUsername() {
            if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitTimestamp = Date.now();
            try {
                this.reset();
                if (typeof this.username !== 'string' || this.username.trim().length === 0 || !isValidEmail(this.username)) {
                    this.inputError = 'Please enter an email address';
                    this.inputErrorTimeout = setTimeout(() => { this.inputError = null; }, 15000); // clear message in 15 seconds
                    return;
                }
                this.$store.commit('loading', { signupWithUsername: true });
                const request = {
                    email: this.username,
                    remember_me: this.isRememberMeChecked,
                };
                console.log(`request ${JSON.stringify(request)}`);
                const response = await this.$client.main().authn.signup(request);
                console.log(`Signup.vue: response ${JSON.stringify(response)}`);

                await this.handleSignupResponse(response);
            } catch (err) {
                console.error('failed to sign up', err);
                await this.handleSignupError(err);
            } finally {
                this.$store.commit('loading', { signupWithUsername: false });
            }
        },
        /**
         * Call this only when you have an interactionId where the interaction type is 'signup'
         */
        async signupWithInteraction() {
            try {
                this.reset();
                if (typeof this.interactionId !== 'string') {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                    return;
                }
                this.$store.commit('loading', { signupWithInteraction: true });
                const request = {
                    interactionId: this.interactionId,
                };
                console.log(`request ${JSON.stringify(request)}`);
                const response = await this.$client.main().authn.signup(request);
                console.log(`Signup.vue: response ${JSON.stringify(response)}`);

                await this.handleSignupResponse(response);
            } catch (err) {
                console.error('failed to sign up', err);
                await this.handleSignupError(err);
            } finally {
                this.$store.commit('loading', { signupWithInteraction: false });
            }
        },
        async handleSignupResponse(response) {
            // email is not verified, redirect to email verification
            if (response.status === 'check_email' && response.interactionId) {
                this.$router.push({ name: 'verify-email', query: { i: response.interactionId } });
                return;
            }

            // email is verified but user not authenticated here; redirect to login
            if (response.status === 'login_required' && response.username) {
                this.$router.push({ name: 'login', query: { username: response.username } });
                return;
            }
            if (response.status === 'login_required') {
                this.$router.push({ name: 'login' });
                return;
            }

            // user record was created; next step is get the app or set up LoginShield
            if (response.status === 'setup_required') {
                // TODO: check if user has loginshield set up yet; if not redirect to that setup; if already set up, check if user has an app linked; if not, tell user about the app and hsow download links
                await this.$store.dispatch('loadSession');
                this.$router.push({ name: 'dashboard' }); // TODO: may need to redirect somewhere else for setup
                return;
            }

            // user is already authenticated with same email address
            if (response.status === 'authenticated') {
                this.$router.push({ name: 'dashboard' });
                return;
            }

            console.error(`signupWithUsername error: unexpected status from server: ${JSON.stringify(response.status)}`);
            this.serverError = true;
            this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
        },
        async handleSignupError(err) {
            if (err.response?.status) {
                console.error(`response status: ${err.response.status}`);
                // TODO: 300 error codes? server shouldn't be redirecting us...
                if (err.response.status === 403) {
                    this.reset();
                    this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                    this.forbiddenError = true;
                    this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                } else if (err.response.status >= 400 && err.response.status < 500) {
                    this.requestError = true;
                    this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                } else if (err.response.status >= 500) {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } else {
                this.serverError = true;
                this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
            }
        },
        async loadInteraction() {
            try {
                this.$store.commit('loading', { loadInteraction: true });
                const response = await this.$client.main().interaction.get(this.interactionId);
                console.log(`response: ${JSON.stringify(response)}`); // {"type":"verify_email","content":{"antiphishing":true,"email":"jonathan@buhacoff.net","intent":"signup","intent_data":{"username":"jonathan@buhacoff.net"},"brandprofile":"etherlink", "status": "new", "status_on": 1655086282382},"not_after":1655086282382}
                const { type, content, duration } = response;
                if (type !== 'signup') {
                    this.forbiddenError = true; // TODO: should we have a different error for "you got here with an invalid interaction, its not even the right type" ?
                    // we don't do a timeout for this because this error is not recoverable on this page... user would have to go back to previous page to fix it
                    // this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    return;
                }
                this.duration = duration; // TODO: show in UI that user has X time to click on verification email ;  this duration is in millis, need to round to nearest minute then convert to text like "the verification email will expire in about 15 minutes"
                const { brandprofile, status, username } = content;
                this.brandprofile = brandprofile; // start loading brand colors and logos
                this.status = status;
                if (status === 'new') {
                    // the user arrived here with verified email, so ask server to create the profile
                    this.signupWithInteraction();
                } else if (status === 'setup_required') {
                    // TODO: check if user has loginshield set up yet; if not redirect to that setup; if already set up, check if user has an app linked; if not, tell user about the app and hsow download links
                    await this.$store.dispatch('loadSession');
                    this.$router.push({ name: 'dashboard' }); // TODO: may need to redirect somewhere else for setup
                    return;
                } else if (status === 'login_required' && username) {
                    this.$router.push({ name: 'login', query: { username } });
                } else if (status === 'login_required') {
                    this.$router.push({ name: 'login' });
                } else if (status === 'authenticated') {
                    await this.$store.dispatch('loadSession');
                    this.$router.push({ name: 'dashboard' });
                } else {
                    console.log('unexpected status');
                    this.serverError = true;
                }
            } catch (err) {
                console.error('failed to load interaction', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.reset();
                        // this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { loadInteraction: false });
                this.isViewReady = true;
            }
        },
        // onSelectBrand({ alias }) {
        //     if (this.$route.query.intent === 'unsubscribe') {
        //         this.$router.push({ name: 'brand-view-profile', params: { alias }, hash: '#unsubscribe' });
        //     } else {
        //         this.$router.push({ name: 'brand-view-profile', params: { alias } });
        //     }
        // },
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        switchAccount() {
            this.interactionId = null;
            this.reset();
            this.$nextTick(() => {
                setTimeout(() => { this.activate('usernameInput'); }, 1);
            });
        },
    },
    mounted() {
        /*
        The first time user arrives on this page, there would not be an interaction id in the query,
        but there could be an email address to pre-fill if the user is coming from another activity
        where they already entered it, so they don't need to enter it again here (but it's editable
        so they can still change it if they want to).

        The second time the user arrives on this page (after email verification is completed), there
        will be an interaction id in the query, and we can load the username from the server. At this
        point the username is not editable (because it's what the user verified) but if user wants
        to change it we can reset the UI by clearing the interaction id and allowing the user to enter
        a new email address and start over with verification. With this interaction id, we are
        authorized to check if the user already exists and to create the new user record.
        */

        this.username = this.$route.query.email ?? '';

        this.interactionId = this.$route.query.i;

        if (this.interactionId) {
            this.loadInteraction();
        } else {
            this.$nextTick(() => {
                setTimeout(() => { this.activate('usernameInput'); }, 1);
            });
        }
    },
};
</script>
